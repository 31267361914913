<template>
  <div class="bg-white p-3 rounded shadow-sm">
    <h1 class="mb-5">Rekap Pemusnahan Darah</h1>

    <section class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center" style="gap: 1rem">
        <b-form-group label="Bulan" label-for="month-select">
          <b-form-select
            id="month-select"
            v-model="form.selectedMonth"
            :options="months"
            @change="fetchData()"
          />
        </b-form-group>
        <b-form-group label="Tahun" label-for="year-select">
          <b-form-select
            id="year-select"
            v-model="form.selectedYear"
            :options="years"
            @change="fetchData()"
          />
        </b-form-group>
      </div>
      <b-button variant="primary" :disabled="isLoading" @click="print">
        <b-spinner v-if="isLoading" class="mr-2" small></b-spinner>
        Cetak
      </b-button>
    </section>

    <section
      id="print-section"
      class="rounded p-5"
      style="border: 1px solid #ccc"
    >
      <div style="display: flex; justify-content: center; margin-bottom: 8px">
        <img :src="logoSrc" alt="logo" width="35" height="35" />
        <h2 style="margin-bottom: 0; margin-left: 4px">
          UTDRS OTO ISKANDAR DINATA
        </h2>
      </div>
      <p style="text-align: center">
        Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921 Telp.
        081312856201
      </p>
      <hr style="margin: 0; border-top: 1px solid gray" />
      <hr style="margin: 4px 0; border-bottom: 1px solid gray" />
      <h3
        v-show="form.selectedMonth && form.selectedYear"
        class="text-right mt-3 mb-1 mr-3"
      >
        {{ months.find((m) => m.value === form.selectedMonth).text }},
        {{ form.selectedYear }}
      </h3>
      <div class="table-responsive">
        <table class="table table-sm text-center">
          <thead>
            <tr>
              <th rowspan="2">Penyebab Pemusnahan</th>
              <th colspan="5">Produk</th>
              <th colspan="4">Golongan Darah</th>
              <th rowspan="2">Jumlah</th>
            </tr>
            <tr>
              <th>WB</th>
              <th>PRC</th>
              <th>TC</th>
              <th>LP</th>
              <th>FFP</th>
              <th>A Pos</th>
              <th>B Pos</th>
              <th>AB Pos</th>
              <th>O Pos</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td class="text-left">{{ row.penyebab }}</td>
              <!-- Data untuk Produk -->
              <td>{{ row.total_components.WB || 0 }}</td>
              <td>{{ row.total_components.PRC || 0 }}</td>
              <td>{{ row.total_components.TC || 0 }}</td>
              <td>{{ row.total_components.LP || 0 }}</td>
              <td>{{ row.total_components.FFP || 0 }}</td>
              <!-- Data untuk Golongan Darah -->
              <td>{{ row.total_blood_type["A Pos"] || 0 }}</td>
              <td>{{ row.total_blood_type["B Pos"] || 0 }}</td>
              <td>{{ row.total_blood_type["AB Pos"] || 0 }}</td>
              <td>{{ row.total_blood_type["O Pos"] || 0 }}</td>
              <!-- Jumlah -->
              <td>{{ row.total }}</td>
            </tr>
            <!-- Baris Total -->
            <tr>
              <td class="font-weight-bold">Total</td>
              <!-- Total Produk -->
              <td class="font-weight-bold">{{ totals.total_components.WB }}</td>
              <td class="font-weight-bold">
                {{ totals.total_components.PRC }}
              </td>
              <td class="font-weight-bold">{{ totals.total_components.TC }}</td>
              <td class="font-weight-bold">{{ totals.total_components.LP }}</td>
              <td class="font-weight-bold">
                {{ totals.total_components.FFP }}
              </td>
              <!-- Total Golongan Darah -->
              <td class="font-weight-bold">
                {{ totals.total_blood_type["A Pos"] }}
              </td>
              <td class="font-weight-bold">
                {{ totals.total_blood_type["B Pos"] }}
              </td>
              <td class="font-weight-bold">
                {{ totals.total_blood_type["AB Pos"] }}
              </td>
              <td class="font-weight-bold">
                {{ totals.total_blood_type["O Pos"] }}
              </td>
              <!-- Total Keseluruhan -->
              <td class="font-weight-bold">{{ totals.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import html2pdf from "html2pdf.js";
import manageBloodDiscardedAPI from "../../../../api/blood_discarded/manageBloodDiscardedAPI";

export default {
  name: "RekapPemusnahan",
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      form: {
        selectedMonth: "",
        selectedYear: "",
      },
      months: [
        { text: "Pilih Bulan", value: "" },
        { text: "Januari", value: 1 },
        { text: "Februari", value: 2 },
        { text: "Maret", value: 3 },
        { text: "April", value: 4 },
        { text: "Mei", value: 5 },
        { text: "Juni", value: 6 },
        { text: "Juli", value: 7 },
        { text: "Agustus", value: 8 },
        { text: "September", value: 9 },
        { text: "Oktober", value: 10 },
        { text: "November", value: 11 },
        { text: "Desember", value: 12 },
      ],
      years: (() => {
        const years = [{ text: "Pilih Tahun", value: "" }];
        const thisYear = new Date().getFullYear();
        for (let i = 2021; i <= thisYear; i++) {
          years.push({ text: `${i}`, value: i });
        }
        return years;
      })(),
      rows: [],
      productList: ["WB", "PRC", "TC", "LP", "FFP"],
      bloodTypes: ["A Pos", "B Pos", "AB Pos", "O Pos"],
    };
  },
  computed: {
    logoSrc() {
      return require("@/assets/img/logo/logo.svg");
    },
    totals() {
      const totals = {
        total_components: {
          WB: 0,
          PRC: 0,
          TC: 0,
          LP: 0,
          FFP: 0,
        },
        total_blood_type: {
          "A Pos": 0,
          "B Pos": 0,
          "AB Pos": 0,
          "O Pos": 0,
        },
        total: 0,
      };

      this.rows.forEach((row) => {
        // Sum total_components
        this.productList.forEach((product) => {
          totals.total_components[product] +=
            row.total_components[product] || 0;
        });

        // Sum total_blood_type
        this.bloodTypes.forEach((type) => {
          totals.total_blood_type[type] += row.total_blood_type[type] || 0;
        });

        // Sum total
        totals.total += row.total || 0;
      });

      return totals;
    },
  },
  methods: {
    async fetchData() {
      try {
        const { data: response } =
          await manageBloodDiscardedAPI.getBloodDiscardedReport({
            month: this.form.selectedMonth,
            year: this.form.selectedYear,
          });

        this.rows = Object.entries(response.data.total_reason_failed).map(
          ([cause, data]) => ({
            penyebab: cause.toUpperCase(),
            total_components: data.total_components || {},
            total_blood_type: data.total_blood_type || {},
            total: data.total || 0,
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    print() {
      this.isLoading = true;
      const printSection = document.getElementById("print-section");
      printSection.style.border = "none";
      const opt = {
        margin: 0,
        filename: `Rekap_Pemusnahan_${this.form.selectedMonth}_${this.form.selectedYear}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      };
      html2pdf()
        .from(printSection)
        .set(opt)
        .save()
        .then(() => {
          printSection.style.border = "1px solid #ccc";
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.form.selectedMonth = new Date().getMonth() + 1;
    this.form.selectedYear = new Date().getFullYear();
    this.fetchData();
  },
};
</script>

<style scoped>
/* Menebalkan teks pada baris total dan menambahkan background */
.table tbody tr:last-child {
  background-color: #f9f9f9;
}

.table tbody tr:last-child td {
  border-top: 2px solid #dee2e6;
}
</style>
